import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import { rhythm } from '../../utils/typography'
import Image from 'gatsby-image'

class Index extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Layout>
        <SEO
          title={data.markdownRemark.frontmatter.title}
          keywords={[`Live`, `GIF`, `iOS`, `app`, `privacy`, `policy`]}
        />
        <h1>{data.markdownRemark.frontmatter.title}</h1>
        <p>
          <strong>Last updated: </strong>
          {data.markdownRemark.frontmatter.date}
        </p>
        <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    markdownRemark(
      fileAbsolutePath: { regex: "/livetogif/" }
      frontmatter: { date: { eq: "2020-04-28" } }
    ) {
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
      html
    }
  }
`
